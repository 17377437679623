<template>
  <v-container class="contenedor-main">
    <v-data-table
      :headers="headersAlertas"
      :items="alertas"
      :search="searchAlertas"
      sort-by="Nombre"
      dense
      class="elevation-1"
      :header-props="{ sortByText: 'Organizar por' }"
      no-results-text="Sin resultados"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-text': 'items por página',
        'items-per-page-all-text': 'Todos',
        'items-per-page-options': [5, 10, 15, -1],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Alertas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-card-subtitle>
            <v-btn @click="dpsFilter(!sDPS)" small class="filter">
              {{ textdps }}
            </v-btn>
          </v-card-subtitle>
          <v-card-subtitle>
            <v-btn @click="solFilter(!sSOL)" small class="filter">
              {{ textsol }}
            </v-btn>
          </v-card-subtitle>
          <v-card-title>
            <v-text-field
              v-model="searchAlertas"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span class="rtaStyle">
          <v-select
            label="Respuestas"
            :items="rtas"
            item-value="data"
            item-text="value"
            v-model="item.respuesta"
            @change="estadoRta(item)"
            placeholder="Respuestas"
          ></v-select>
        </span>
      </template>
      <template v-slot:[`item.estudianteData`]="{ item }">
        <v-icon small @click="editarInfo(item.estudianteData)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon small @click="deleteAlerta(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.whatsApp`]="{ item }">
        <v-icon
          small
          @click="openWA(item.whatsApp, item.estudianteData.usuario)"
        >
          mdi-phone
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-row>
          <v-col cols="12" md="12">
            <v-container>
              <v-progress-circular
                v-if="alertas.length == 0"
                :width="3"
                :size="20"
                color="primary"
                indeterminate
              >
              </v-progress-circular>
            </v-container>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              small
              v-bind:style="{ 'margin-top': '10px', 'margin-bottom': '10px' }"
              color="primary"
              @click="initialize"
            >
              Volver a cargar
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <!-- dialog de WA -->
    <v-dialog v-model="dialogWA" max-width="30%" v-if="dialogWA">
      <whats-app
        v-on:eventosWa="eventosWApp"
        :name="nameCard"
        :optionsW="optionsW"
      ></whats-app>
    </v-dialog>
    <!-- dialog de info -->
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Información del usuario </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.password"
                  label="Constraseña"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.correo"
                  label="Correo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.passCorreo"
                  label="Contraseña de correo"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn
            color="primary"
            :disabled="isGuardando"
            text
            @click="saveInfo(editedItem)"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import WhatsApp from "./component/WhatsApp.vue";
import data from "./Tools/data";
import { mapState } from "vuex";

export default {
  components: {
    WhatsApp: () =>
      import(/* webpackChunkName: "WhatsApp"  */ "./component/WhatsApp.vue"),
    // WhatsApp,
  },

  computed: mapState(["respuesta"]),

  data() {
    return {
      isGuardando: false,
      searchAlertas: "",
      headersAlertas: [
        {
          text: "Docente",
          sortable: true,
          value: "profesor",
        },
        {
          text: "Usuario",
          sortable: true,
          value: "estudianteData.usuario",
        },
        {
          text: "Materia",
          sortable: true,
          value: "materia",
        },
        {
          text: "Periodo",
          sortable: true,
          value: "periodo",
        },
        {
          text: "Alerta",
          sortable: false,
          value: "comentarios",
        },
        {
          text: "Linea",
          sortable: false,
          value: "linea",
        },
        {
          text: "WApp",
          sortable: false,
          value: "whatsApp",
        },
        {
          text: "Respuesta",
          sortable: false,
          value: "actions",
        },
        {
          text: "Editar info",
          sortable: false,
          value: "estudianteData",
        },
        {
          text: "",
          sortable: false,
          value: "delete",
        },
      ],
      alertas: [],
      rtas: [],
      respuesta: null,
      dialog: false,
      editedItem: {
        _id: null,
        password: null,
        correo: null,
        passCorreo: null,
      },
      dialogWA: false,
      nameCard: null,
      optionsW: [],
      textdps: "Ocultar DPS",
      textsol: "Ocultar SOL",
      sDPS: true,
      sSOL: true,
    };
  },

  async created() {
    if (this.$store.state.respuesta.length == 0) {
      await data.dataList("/tiposRespuesta", "lista", "listar", "respuesta");
    }
    var aler;
    if (this.$store.state.alertas.length == 0) {
      aler = await data.dataList("/alertas", "lista", "listar", "alertas");
      this.create(aler);
    } else {
      aler = this.$store.state.alertas;
      this.create(aler);
    }
  },

  mounted() {
    document.title = "Alertas";
  },

  methods: {
    async initialize() {
      this.alertas = [];
      var aler;
      if (this.$store.state.alertas.length == 0) {
        aler = await data.dataList("/alertas", "lista", "listar", "alertas");
        this.create(aler);
      } else {
        aler = this.$store.state.alertas;
        this.create(aler);
      }
    },

    create(a) {
      var alertas = a;
      for (var j = 0, len = alertas.length; j < len; j++) {
        if (alertas[j]["estado"] != "cerrado") {
          this.alertas.push(alertas[j]);
        }
      }
      const rtas = this.$store.state.respuesta;
      for (var p = 0, leng = rtas.length; p < leng; p++) {
        this.rtas.push(this.$store.state.respuesta[p]["nombre"]);
      }
    },

    estadoRta(data) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("alertaId", data._id);
          fd.append("rta", data.respuesta);
          axios.post(host + "/respuestas", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("Error al solucionar la alerta, intenta nuevamente");
            } else {
              this.$alert("Se solucionó la alerta");
              this.initialize();
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    editarInfo(data) {
      this.dialog = true;
      this.editedItem = Object.assign({}, data);
    },

    saveInfo(data) {
      this.isGuardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("idE", data._id);
          fd.append("nombre", data.nombre);
          fd.append("documento", data.documento);
          fd.append("telefono", data.telefono);
          fd.append("telefono2", data.telefono2);
          fd.append("usuario", data.usuario);
          fd.append("password", data.password);
          fd.append("correo", data.correo);
          fd.append("passCorreo", data.passCorreo);
          fd.append("edad", data.edad);
          fd.append("cead", data.cead);
          fd.append("carrera", data.carrera);
          fd.append("genero", data.genero);
          fd.append("activo", data.activo);
          fd.append("permisoAdmin", "SI");
          axios.post(host + "/editaEstudiante", fd).then((result) => {
            if (result.data.text == "OK") {
              this.dialog = false;
              this.isGuardando = true;
            } else {
              this.$alert(
                "Error al intentar guardar esta información, intentelo nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    openWA(data, d) {
      this.dialogWA = true;
      this.optionsW = data;
      this.nameCard = d;
    },

    closeWA() {
      this.dialogWA = false;
      this.nameCard = null;
    },

    dpsFilter(sDPS) {
      if (sDPS == true) {
        this.alertas = [];
        this.textdps = "Ocultar DPS";
        var info = this.$store.state.alertas;
        for (var f = 0; f < info.length; f++) {
          if (info[f]["estado"] != "cerrado") {
            this.alertas.push(info[f]);
          }
        }
        this.sDPS = true;
      } else {
        var data = this.alertas;
        this.alertas = [];
        for (var d = 0; d < data.length; d++) {
          if (data[d]["respuesta"] != "DPS") {
            this.alertas.push(data[d]);
          }
        }
        this.sDPS = false;
        this.textdps = "Mostrar DPS";
      }
    },

    solFilter(sSOL) {
      if (sSOL == true) {
        this.alertas = [];
        this.textsol = "Ocultar SOL";
        var info = this.$store.state.alertas;
        for (var f = 0; f < info.length; f++) {
          if (info[f]["estado"] != "cerrado") {
            this.alertas.push(info[f]);
          }
        }
        this.sSOL = true;
      } else {
        var data = this.alertas;
        this.alertas = [];
        for (var d = 0; d < data.length; d++) {
          if (data[d]["respuesta"] != "SOL") {
            this.alertas.push(data[d]);
          }
        }
        this.sSOL = false;
        this.textsol = "Mostrar SOL";
      }
    },

    deleteAlerta(item) {
      this.$confirm({
        auth: true,
        message: `Escribe "borrar" para eliminar la alerta`,
        button: {
          no: "Cancelar",
          yes: "Aceptar",
        },
        callback: (confirm, password) => {
          if (confirm == true && password.toLowerCase() == "borrar") {
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const fd = new FormData();
                fd.append("token", idToken);
                fd.append("tipo", "eliminar");
                fd.append("alertaId", item._id);
                axios.post(host + "/alertas", fd).then((result) => {
                  let data = result.data.text;
                  if (data == "OK") {
                    this.$alert("Alerta eliminada");
                    this.initialize();
                  }
                });
              })
              .catch(function (error) {
                this.$alert("Error " + error.message);
              });
            this.$confirm.close();
          }
        },
      });
    },

    eventosWApp(d) {
      if (d == false) this.dialogWA = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  margin-right: 10px !important;
}
.rtaStyle {
  display: inline-block !important;
  margin-right: 20% !important;
}
</style>